import React, { useEffect, useState } from 'react'
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader'
import secureLocalStorage from 'react-secure-storage'
import { SubscribeFund, getFundSubscriptionDetails, getFundbyId } from '../services/services';
import StepContainer from './StepContainer';
import { questionPatterns, stepsToShowWhile, subscriptionMode, subscriptionValidateFields } from '../services/apiDataTemplate';
import { LoadingButton } from '@mui/lab';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { fundSubscriptionStatues } from '../../CODashboard/Services/apiDataTempate';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { subscriptioToastMessages } from '../../../utils/AppConstants';
import { FormControlLabel, Switch } from '@mui/material';
import OfflineSubscriptionPage from './offline-subscription/OfflineSubscriptionPage';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import { convertArrayToString } from '../../common/Functions/ConvertStringtoArray';

const pageDescription = "Download & submit your subscription form"

//#This component is for subscription page from innvestor marketplace
const SubscriptionMain = () => {

  //#region variables
  const [currPage, setCurrPage] = useState(subscriptionMode.ONLINE_SUBSCRIPTION);
  const [investorSubscriptionDetails, setInvestorSubscriptionDetails] = useState();
  const [EsignClicked, setEsignClicked] = useState()
  const [sbtLoading, setSbtLoading] = useState(false);
  const [disableSbtBtn, setDisableSbtButton] = useState(false)
  const navigate = useNavigate();
  const investorId = secureLocalStorage.getItem("userId");
  const fundID = secureLocalStorage.getItem("FundId");

  //#region click events
  const handleToggleClick = () => {
    if (currPage === subscriptionMode.ONLINE_SUBSCRIPTION) {
      setCurrPage(subscriptionMode.OFFLINE_SUBSCRIPTION)
    }
    else {
      setCurrPage(subscriptionMode.ONLINE_SUBSCRIPTION)
    }
  }
  const onBackClick = () => {
    navigate("/marketplace");
  }

  const onSubmitSubscription = (esign) => {
    setSbtLoading(true);
    setEsignClicked(esign)
  }

  //#region api get calls
  const getSubscriptionDetails = async () => {
    const subscriptionID = secureLocalStorage.getItem("subscriptionId") || 0;
    const investorId = secureLocalStorage.getItem("userId")
    const fundID = secureLocalStorage.getItem("FundId")
    const data = await getFundSubscriptionDetails(fundID, investorId, subscriptionID);
    if (data.responseCode === 200) {
      /*Testing Purpose 
      let receivedData = data.responseData;
      debugger
      receivedData.questionBankAndSteps.userQuestionAndAnswers[0].userProvidedAnswer = ""; */
      setInvestorSubscriptionDetails(data.responseData);
    }
    else {
      setInvestorSubscriptionDetails(null);
    }
  }

  //#region api post calls
  const submitSubscriptionAnswers = async (questionsDetails) => {
    const requestedData = questionsDetails
      /* ?.filter((question) =>
        (question.userProvidedAnswer !== null && question.userProvidedAnswer !== "") ||
        (question.multiChoiceQuestionAnswerIDs !== null && question.multiChoiceQuestionAnswerIDs.length > 0) ||
        (question.questionAnswerID !== null && question.questionAnswerID !== 0)) */
      ?.map((item) => ({
        "UserProfileID": item?.subscriptionID === 0 ? 0 : item?.userProfileID,
        "QuestionBankID": item?.questionBankID,
        "QuestionName": item?.questionName,
        "UserID": investorId,
        "fundID": 0,
        "SubscriptionID": item?.subscriptionID || 0,
        "QuestionPatternTypeID": item?.questionPatternTypeID,
        "QuestionPatternType": item?.questionPatternType,
        "QuestionAnswerID": item?.questionAnswerID || 0,
        "UserProvidedAnswerinText": item.questionPatternType === questionPatterns.FILE_UPLOAD
          ? item?.userProvidedAnswer instanceof Blob || item?.userProvidedAnswer instanceof File 
             ? item?.userProvidedAnswer?.name : item?.userProvidedAnswer
        : item.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION ? convertArrayToString(item?.multiChoiceQuestionAnswerIDs)
          : item.questionPatternType === questionPatterns.GRID
            ? (JSON.stringify(item?.userProvidedAnswer) || "")
            : item?.userProvidedAnswer || "",
        "multiChoiceQuestionAnswerIDs": item?.multiChoiceQuestionAnswerIDs || [],
        "bookmarks": item?.bookmarks
        /* "UploadedFile": item?.uploadedFile || '', */
      })) || [];

    const formData = new FormData();

    const filteredFileQuestions = questionsDetails
      ?.filter((question) => {
        return question.questionPatternType === questionPatterns.FILE_UPLOAD;
      });

    for (const question of filteredFileQuestions) {
      const userProvidedAnswer = question?.userProvidedAnswer;
      if (userProvidedAnswer instanceof Blob || userProvidedAnswer instanceof File) {
        formData.append("fileList", userProvidedAnswer, `${question.questionBankID}/${userProvidedAnswer.name}`);
      }
    }

    formData.append("userProvidedAnswers", JSON.stringify(requestedData))
    const fundTypeId = investorSubscriptionDetails?.fundBasicDetails?.fundTypeId
    const investorTypeID = investorSubscriptionDetails?.investorTypeID
    const subscriptionID = investorSubscriptionDetails?.subscriptionID;
    let capitalCommited = 0; 
    const isOffListSubscription = currPage === subscriptionMode.OFFLINE_SUBSCRIPTION

    if(investorSubscriptionDetails?.fundBasicDetails?.fundTypeName === "Private Credit"){
      capitalCommited = requestedData?.find((question) => question.QuestionName === subscriptionValidateFields.SUBSCRIPTION_AMOUNT)?.UserProvidedAnswerinText;
    }
    else{
      capitalCommited = requestedData?.find((question) => question.QuestionName === subscriptionValidateFields.COMMITED_CAPITAL)?.UserProvidedAnswerinText;
    }

    const data = await SubscribeFund(fundID,fundTypeId,investorTypeID, investorId, subscriptionID, capitalCommited, isOffListSubscription, EsignClicked, formData);
    if (data.responseCode === 200) {
      if (EsignClicked && data.responseData?.investorDocuSignURL?.toString() !== "") {
        var inputUrl = data.responseData?.investorDocuSignURL?.toString();
        window.location.href = inputUrl;
      }
      else if (!EsignClicked) {
        setSbtLoading(false);
        getSubscriptionDetails();
        toast.success(subscriptioToastMessages.STEP_DETAILS_SUCCESS,
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      } else {
        toast.error(subscriptioToastMessages.INVESTOR_SIGN_FAILED,
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }



      // if ((data.responseData?.subscriptionStatusName === fundSubscriptionStatues.INVESTOR_SIGN_PENDING && EsignClicked)
      //   || data.responseData?.canInvestorEdit) {
      //   var inputUrl = data.responseData?.investorDocuSignURL?.toString();
      //   if (inputUrl) {
      //     window.location.href = inputUrl;
      //   }
      //   else {
      //     toast.error(subscriptioToastMessages.INVESTOR_SIGN_FAILED,
      //       { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      //   }
      // }

      // if (investorSubscriptionDetails?.subscriptionStatusName !== fundSubscriptionStatues.INVESTOR_SIGN_PENDING
      //   || !investorSubscriptionDetails?.canInvestorEdit) {
      //   getSubscriptionDetails();
      //   setSbtLoading(false);
      //   toast.success(subscriptioToastMessages.SUBSCRIPTION_SUCESSFUL,
      //     { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      // }
    }
    else {
      setSbtLoading(false);
      getSubscriptionDetails();
      if (investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.INVESTOR_SIGN_PENDING) {
        toast.error(subscriptioToastMessages.INVESTOR_SIGN_FAILED,
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
      else {
        toast.error(subscriptioToastMessages.SUBSCRIPTION_FAILED,
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }
  }

  //#region useeffect
  useEffect(() => {
    getSubscriptionDetails();
  }, [])

  //#region render functions
  const renderSubscriptionMode = () => {
    switch (currPage) {
      case subscriptionMode.ONLINE_SUBSCRIPTION:
        return <>
          <b>
            Subscription Steps
          </b>
          <div>
            <StepContainer
              investorId={investorId}
              type={stepsToShowWhile.SUBSCRIBING}
              fundID={fundID}
              subscriptionId={0}
              investorSubscriptionDetails={investorSubscriptionDetails}
              getQuestionAnswerDetails={getSubscriptionDetails}
              sbtLoading={sbtLoading}
              setSbtLoading={setSbtLoading}
              setDisableSbtButton={setDisableSbtButton}
              submitSubscriptionAnswers={submitSubscriptionAnswers} />
          </div>
        </>
      case subscriptionMode.OFFLINE_SUBSCRIPTION:
        return <OfflineSubscriptionPage investorSubscriptionDetails={investorSubscriptionDetails} investorId={investorId} fundID={fundID} />
      default:
        return "Invalid Step"
    }
  }

  return (
    <div>
      <div>
        <PageHeader
          template={HeaderTemplates.Standard}
          headerImage={investorSubscriptionDetails?.fundBasicDetails?.fundLogo}
          customBackgroundImage={
            investorSubscriptionDetails?.fundBasicDetails?.fundBGImage
              ? investorSubscriptionDetails?.fundBasicDetails?.fundBGImage
              : "/DefaultBackgroundImage.png"
          }
          fundName={secureLocalStorage.getItem("FundName")} />
      </div>
      <div className='wrapper white-card child-margin-15 margin-top-minus-20 margin-bottom-70'>
        <div className='space-between'>
          <h6>
            Subscribe to Fund
          </h6>
          <div>
            <FormControlLabel
              label={currPage === subscriptionMode.ONLINE_SUBSCRIPTION ? "ONLINE" : "OFFLINE"}
              control={<Switch checked={currPage === subscriptionMode.ONLINE_SUBSCRIPTION}
                onChange={(ev) => {
                  handleToggleClick(ev.target.checked);
                }} />} />
          </div>
        </div>
        <div>
          {currPage === subscriptionMode.ONLINE_SUBSCRIPTION ? "Complete the secure online subscription in just a few steps" : pageDescription}
        </div>
        <>
          {renderSubscriptionMode()}
        </>
        {
          currPage === subscriptionMode.ONLINE_SUBSCRIPTION &&
          <div className='space-between margin-top-20 margin-bottom-10'>
            <div> <div>
              <LoadingButton
                variant='outlined'
                size="large"
                onClick={onBackClick}
                startIcon={<KeyboardBackspaceIcon />}
                disabled={sbtLoading}
                loadingPosition="start">
                <p className={`actionButtonLabel`}>BACK TO MARKETPLACE</p>
              </LoadingButton>
            </div></div>
            <div className='child-row-margin-5'>

              {

                <div>
                  <LoadingButton
                    variant='contained'
                    size="large"
                    loading={sbtLoading}
                    onClick={() => { onSubmitSubscription(false) }}
                    //onClick={onBackClick}
                    startIcon={<SaveIcon />}
                    disabled={disableSbtBtn}
                    loadingPosition="start">
                    <p className={`actionButtonLabel`}>SAVE</p>
                  </LoadingButton>
                </div>

              }

              {

                <div>
                  <LoadingButton
                    variant='contained'
                    size="large"
                    loading={sbtLoading}
                    startIcon={<PanToolAltIcon />}
                    disabled={disableSbtBtn}
                    onClick={() => { onSubmitSubscription(true) }}
                    loadingPosition="start">
                    <p className={`actionButtonLabel`}>SUBMIT & E-SIGN</p>
                  </LoadingButton>
                </div>
              }
              {/* {
                investorSubscriptionDetails?.subscriptionID > 0 &&
                investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.INVESTOR_SIGN_PENDING &&
                <div>
                  <LoadingButton
                    variant='contained'
                    size="large"
                    loading={sbtLoading}
                    startIcon={<DriveFileRenameOutlineIcon />}
                    onClick={onSubmitSubscription}
                    loadingPosition="start">
                    <p className={`actionButtonLabel`}>E - Sign</p>
                  </LoadingButton>
                </div>
              } */}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default SubscriptionMain