import React, { useEffect, useState } from 'react'
import ActionButton from '../../../../common/ActionButton/ActionButton';
import { KycAmlStuatus, KycStatusvalues } from '../../../../KYCVerification/enum';
import { subscriptioToastMessages, userProfileToastMessages, validationsConstants, VirtualDataRoomToastMessages } from '../../../../../utils/AppConstants';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ToastContainer, toast } from 'react-toastify';
import { getKycAmlDetails, updatekycamlstatus } from '../../../../KYCVerification/services/services';
import { Button, Grid, LinearProgress, Tooltip } from '@mui/material';
import DiscussionBox from '../../../../KYCVerification/components/DiscussionBox';
import VDRoom from '../../../../common/VirtualDataRoom/VDRoom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import secureLocalStorage from 'react-secure-storage';
import { convertStringToArrayWithEmptyArray, trimAndLowercaseArray } from '../../../../common/Functions/ConvertStringtoArray';
import { kycFieldsData } from '../../../services/fieldsData';
import SelectField from '../../../../common/input-fields/SelectField';
import ALTTextField from '../../../../common/input-fields/ALTTextField';
import { questionPatterns } from '../../../../investor-fund-subscription/services/apiDataTemplate';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DateField from '../../../../common/input-fields/DateField';
import FileUploadBtn from '../../../../common/FileUploadButton/FileUploadBtn';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { questionsTypesEnum } from '../../../services/apiDataTemplate';
import { getUserQuestionBank, saveSubscriptionAnswers } from '../../../../investor-fund-subscription/services/services';
import MultiSelectField from '../../../../common/input-fields/MultiSelectField';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import { axiosApiCallForFiles } from '../../../../Services/axiosApiCallForFiles';
import { deleteVDRItem } from '../../../../InvestorReporting/FirmEmployees/services/services';
import { AppDashboards } from '../../../../../utils/enum';
import { dateValidationTypeValues } from '../../../../common/TextInput/appInputenum';
import RadioGroupField from '../../../../common/input-fields/RadioGroupField';

const QuestionsModel = (props) => {

  //#region variables
  const { verificationType, isKycVerification, investorDetails, refreshDetails, setRefreshDetails, isViewOnly } = props;

  //#region variables
  const investorId = investorDetails?.personalInformation?.userId
  const [loading, setLoading] = useState(false);
  const [showComments, setshowComments] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [initialKYCData, setInitialKYCData] = useState({});
  const [initialAMLData, setInitialAMLData] = useState({});
  const [validationFields, setValidationFields] = useState([]);//for dependent validations

  //#region functions
  const showThisQuestion = (crntQuestion) => {
    if (crntQuestion?.parentQuestionID === 0) {
      return false;
    }

    var parentQuestion = questionsDetails?.values?.find(u => u.questionBankID === crntQuestion?.parentQuestionID);

    if (parentQuestion?.questionPatternType === questionPatterns.HEADER) {
      return true;
    }

    if (parentQuestion && crntQuestion.checkParentAnswer) {
      const answersArray = convertStringToArrayWithEmptyArray(crntQuestion?.showIfParentAnswerIDIsIn)
      //#TODO: Need to re validate
      /* if (answersArray?.length > 0) {
          return (
              answersArray.some(answer => parentQuestion.multiChoiceQuestionAnswerIDs?.includes(answer)) ||
              answersArray.includes(parentQuestion.questionAnswerID)
          );
      }
      else{ */
      const parentAnswerArray = convertStringToArrayWithEmptyArray(parentQuestion.userProvidedAnswer.trim().toLowerCase())
      const childAnswerArray = convertStringToArrayWithEmptyArray(crntQuestion.showIfParentAnswerIs.trim().toLowerCase());

      const trimmedParentAnswerArray = trimAndLowercaseArray(parentAnswerArray);
      const trimmedChildAnswerArray = trimAndLowercaseArray(childAnswerArray);

      const result = trimmedChildAnswerArray?.includes(trimmedParentAnswerArray[0]);

      return result
      /* } */

    }
    return false;
  }

  //#region change events
  const handleFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleTextChange(name, file);
    }
    else {
      handleTextChange(name, '');
    }
  }

  const handleTextChange = (name, value) => {
    const questionIndex = questionsDetails.values.findIndex((item) => item.questionBankID === parseInt(name));
    if (questionIndex !== -1) {
      questionsDetails.setFieldValue(
        `${questionIndex}.userProvidedAnswer`,
        value
      );
    }
  };

  const handleSelectChange = (name, value) => {
    debugger;
    const questionIndex = questionsDetails.values.findIndex((item) => item.questionBankID === parseInt(name));
    if (questionIndex !== -1) {
      questionsDetails.setFieldValue(
        `${questionIndex}.questionAnswerID`,
        parseInt(value)
      );
      questionsDetails.setFieldValue(
        `${questionIndex}.userProvidedAnswer`,
        questionsDetails.values[questionIndex]
          ?.questionAnswer?.find((item) => item?.questionAnswerID === parseInt(value))?.answer
      );
    }

    if (questionsDetails.values[questionIndex].stopIfAnswerIDIs == parseInt(value)) {
      toast.error(subscriptioToastMessages.STOP_SUBSCRIPTION,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  };

  const handleMultiSelectChange = (name, value) => {
    const questionIndex = questionsDetails.values.findIndex((item) => item.questionBankID === parseInt(name));
    if (questionIndex !== -1) {
      questionsDetails.setFieldValue(
        `${questionIndex}.multiChoiceQuestionAnswerIDs`,
        value
      );
    }
  };

  //#region click events
  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const onSaveClick = () => {
    postSubscriptionAnswers();
  }

  const onDeleteClick = (vdrid) => {
    deleteFile(vdrid);
  }

  const onDownloadClick = (vdrid, title) => {
    downloadFile(vdrid, title);
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    setLoading(false);
    getQuestionsDetails();
    setRefreshDetails(false);
  }



  //#region api get calls
  const getQuestionsDetails = async () => {
    const data = await getUserQuestionBank(investorId, verificationType);
    if (data.responseCode === 200) {
      questionsDetails.setValues(data.responseData?.userQuestionAndAnswers);
      if (verificationType == "KYC") {
        setInitialKYCData(data.responseData?.userQuestionAndAnswers);
      }
      if (verificationType == "AML") {
        setInitialAMLData(data.responseData?.userQuestionAndAnswers);
      }
    }
    else {
      questionsDetails.setValues(data.responseData);
    }
  }


  //#region api post calls
  const postSubscriptionAnswers = async () => {
    setLoading(true);
    const requestedData = questionsDetails?.values
      ?.filter((question) =>
        (question.userProvidedAnswer !== null) ||
        (question.multiChoiceQuestionAnswerIDs !== null) ||
        (question.questionAnswerID !== null))
      ?.map((item) => ({
        "UserProfileID": item?.userProfileID,
        "QuestionBankID": item?.questionBankID,
        "UserID": investorId,
        "fundID": 0,
        "SubscriptionID": 0,
        "QuestionPatternTypeID": item?.questionPatternTypeID,
        "QuestionPatternType": item?.questionPatternType,
        "QuestionAnswerID": item?.questionAnswerID || 0,
        "UserProvidedAnswerinText": item.questionPatternType === questionPatterns.FILE_UPLOAD
          ? (item?.userProvidedAnswer?.name || "")
          : item.questionPatternType === questionPatterns.GRID
            ? (JSON.stringify(item?.userProvidedAnswer) || "")
            : item?.userProvidedAnswer || "",
        "multiChoiceQuestionAnswerIDs": item?.multiChoiceQuestionAnswerIDs || [],
        /* "UploadedFile": item?.uploadedFile || '', */
      })) || [];

    const formData = new FormData();

    const filteredFileQuestions = questionsDetails?.values
      ?.filter((question) => question.userProvidedAnswer !== null && question.userProvidedAnswer !== "")
      ?.filter((question) => question.questionPatternType === questionPatterns.FILE_UPLOAD)
      ?.filter((question) => question?.userProvidedAnswer instanceof Blob || question?.userProvidedAnswer instanceof File);

    for (const question of filteredFileQuestions) {
      formData.append("subscriptionFiles", question?.userProvidedAnswer, `${question?.questionBankID}/${question?.userProvidedAnswer?.name}`);
    }

    if (filteredFileQuestions?.length === 0) {
      formData.append("subscriptionFiles", null)
    }

    formData.append("userProvidedAnswers", JSON.stringify(requestedData))
    formData.append("questionType", verificationType)

    const data = await saveSubscriptionAnswers(formData);
    if (data.responseCode === 200) {
      onCancelClick();
      toast.success(userProfileToastMessages.KYC_SUCCESSFUL,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
    else {
      onCancelClick();
      toast.error(userProfileToastMessages.KYC_FAILED,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region api downlaod calls
  const downloadFile = async (vdrid, title) => {
    const data = await axiosApiCallForFiles(`/v1/VirtualDataRooms/DownloadVDRItem?vdrID=${vdrid}`, title)
    if (data.responseCode === 200) {
      toast.success(VirtualDataRoomToastMessages.FILE_SUCCESS_DOWNLOAD, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    } else {
      toast.warning(VirtualDataRoomToastMessages.ACTION_FAILED, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  };

  //#region api delete calls
  const deleteFile = async (vdrid) => {
    const data = await deleteVDRItem(vdrid);
    if (data.responseCode === 200) {
      toast.success(VirtualDataRoomToastMessages.FILE_DELETE_SUCCESS, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    } else {
      toast.warning(VirtualDataRoomToastMessages.FILE_DELETE_FAILED, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  };

  //#region formik validations
  const createValidationSchema = (details, isSubmitting) => {
    return (values) => {
        const errors = {};
        details
            ?.filter(question => question?.setValidations && showThisQuestion(question))
            ?.forEach(question => {
                const currQuestionIndex = questionsDetails.values.findIndex((item) => item.questionBankID === question?.questionBankID);
                
                if (question?.questionPatternType === questionPatterns.TEXT_BOX) {
                  
                    const value = details[currQuestionIndex]?.userProvidedAnswer;
                    if ((!value || value === '')) {
                        errors[question.questionBankID] = validationsConstants.REQUIRED;
                    }
                }
                else if (question?.questionPatternType === questionPatterns.DROP_DOWN) {
                    const value = details[currQuestionIndex]?.questionAnswerID;
                    if (parseInt(value) === 0) {
                        errors[question.questionBankID] = validationsConstants.REQUIRED;
                    }
                }
                else if (question?.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION) {
                    const value = values[currQuestionIndex]?.multiChoiceQuestionAnswerIDs;
                    if (!value || value?.length === 0) {
                        errors[question.questionBankID] = validationsConstants.REQUIRED;
                    }
                }
                else if (question?.questionPatternType === questionPatterns.FILE_UPLOAD) {
                  const value = details[currQuestionIndex]?.userProvidedAnswer;
                    if ((!value || value === '')) {
                        errors[question.questionBankID] = validationsConstants.REQUIRED;
                }
                else if (question?.questionPatternType === questionPatterns.DATE_TIME) {
                  debugger
                  const value = details[currQuestionIndex]?.userProvidedAnswer;
                  if ((!value || value === '')) {
                    debugger
                      errors[question.questionBankID] = validationsConstants.REQUIRED;
                  }
                }
              }
                // Add more custom validations as needed
            });
        return errors;
    };
  };

  const questionsDetails = useFormik({
    initialValues: validationFields,
    validate: isSaveEnabled ? createValidationSchema(validationFields, false) : "",
    onSubmit: async (values) => {
      onSaveClick();
    },
  });

  const handleValidationSubmit = async () => {

    const errors = createValidationSchema(validationFields, true)(questionsDetails.values);
    if (Object.keys(errors).length === 0) {
      questionsDetails.setTouched([]);
      questionsDetails.handleSubmit();
    } else {
      Object.keys(errors).forEach(field => {
        questionsDetails?.setFieldTouched(field, true, false);
      });
      questionsDetails?.setErrors(errors);
    }
  };

  //#region useeffect
  useEffect(() => {
    getQuestionsDetails();
  }, [])

  useEffect(() => {
    if (refreshDetails) {
      getQuestionsDetails();
    }
  }, [refreshDetails])

  useEffect(() => {
    setValidationFields(questionsDetails?.values);
    let initialData;
    if (verificationType == "KYC") {
      initialData = initialKYCData;
    }
    else {
      initialData = initialAMLData;
    }
    if (JSON.stringify(initialData) == JSON.stringify(questionsDetails.values)) {
      setIsSaveDisabled(true);
    }
    else {
      setIsSaveDisabled(false);
    }
  }, [questionsDetails.values])

  useEffect(() => {
    questionsDetails.setValues(validationFields);
  }, [validationFields])

  //#region return
  return (
    <div className='child-margin-15'>
      <>
        {
          isKycVerification ? "" :
            <div className='space-between margin-top-minus-15'>
              <h4>{verificationType}</h4>
              <div className='child-row-margin-5'>
                {
                  isSaveEnabled ?
                    <>
                      <ActionButton
                        label="CANCEL"
                        icon={<CancelIcon />}
                        disabled={loading}
                        variant='outlined'
                        onClick={onCancelClick} />
                      <ActionButton
                        label="SAVE"
                        loading={loading}
                        icon={<SaveIcon />}
                        disabled={isSaveDisabled}
                        onClick={handleValidationSubmit} />
                    </> :
                    <>{
                      !isViewOnly &&
                      <ActionButton
                        label="EDIT"
                        icon={<EditIcon />}
                        onClick={onEditClick} />
                    }
                    </>
                }
              </div>
            </div>
        }
      </>
      <div className='white-card child-margin-15'>
        {questionsDetails?.values?.length > 0 ?
          <div className='display-row-items-flex'>
            {
              questionsDetails?.values
                ?.sort((a, b) => a?.questionNo - b?.questionNo)
                ?.map((question) => {
                  return (
                    <>
                      {
                        showThisQuestion(question) ?
                          <>
                            {
                              question?.questionPatternType === questionPatterns.HEADER &&
                              <div className="width-98" key={question?.questionBankID}>
                                <h6>{question?.questionName}</h6>
                              </div>
                            }
                            {
                              question?.questionPatternType === questionPatterns.TEXT_BOX &&
                              <div className=
                                {(question?.doNotDisplayQuestion || question?.isFullWidth) ?
                                  "width-98 child-margin-0" : "width-45 child-margin-0"}
                                key={question?.questionBankID}>
                                <div className='margin-top-5'>
                                  <ALTTextField
                                    textFormatType={question?.gridSchema}
                                    name={question?.questionBankID}
                                    label={question?.doNotDisplayQuestion ? "" :
                                      question?.questionName}
                                    value={question?.userProvidedAnswer}
                                    multiline={question?.isText}
                                    rows={question?.isText ? 4 : 1}
                                    readOnly={!isSaveEnabled}
                                    onChange={(name, value) => {
                                      handleTextChange(name, value);
                                    }}
                                    required={question?.setValidations}
                                    charactersMaxLength={question?.characterLength}
                                    onBlur={questionsDetails.handleBlur}
                                    error={(questionsDetails?.touched[question?.questionBankID] || question?.userProvidedAnswer !== "") && Boolean(questionsDetails.errors[question.questionBankID])}
                                    errorMessage={(questionsDetails?.touched[question?.questionBankID] || question?.userProvidedAnswer !== "") && questionsDetails.errors[question.questionBankID]} />
                                </div>
                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                  {question?.description}
                                </a>
                              </div>
                            }
                            {
                              question?.questionPatternType === questionPatterns.DROP_DOWN &&
                              <div className={question?.isFullWidth ? "width-98" : "width-33"} key={question?.questionBankID}>
                                <div>
                                  <SelectField
                                    name={question?.questionBankID}
                                    label={question?.questionName}
                                    value={question?.questionAnswerID}
                                    readOnly={!isSaveEnabled}
                                    onChange={(name, value) => { handleSelectChange(name, value) }}
                                    options={question?.questionAnswer?.map(option => ({ label: option.answer, value: option.questionAnswerID }))}
                                    required={question?.setValidations}
                                    onBlur={questionsDetails.handleBlur}
                                    error={questionsDetails?.touched[question?.questionBankID] && Boolean(questionsDetails.errors[question.questionBankID])}
                                    errorMessage={questionsDetails?.touched[question?.questionBankID] && questionsDetails.errors[question.questionBankID]} />
                                </div>
                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                  {question?.description}
                                </a>
                              </div>
                            }
                            {
                              question?.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION &&
                              <div className={question?.isFullWidth ? "width-98" : "width-45"} key={question?.questionBankID}>
                                <div>
                                  <MultiSelectField
                                    name={question?.questionBankID}
                                    label={question?.questionName}
                                    value={question?.multiChoiceQuestionAnswerIDs || []}
                                    readOnly={!isSaveEnabled}
                                    allOption={true}
                                    onChange={(name, value) => { handleMultiSelectChange(name, value) }}
                                    options={question?.questionAnswer?.map(option => ({ label: option.answer, value: option.questionAnswerID }))}
                                    required={question?.setValidations}
                                    onBlur={questionsDetails.handleBlur}
                                    error={questionsDetails?.touched[question?.questionBankID] && Boolean(questionsDetails.errors[question.questionBankID])}
                                    errorMessage={questionsDetails?.touched[question?.questionBankID] && questionsDetails.errors[question.questionBankID]} />
                                </div>
                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                  {question?.description}
                                </a>
                              </div>
                            }
                            {
                              question?.questionPatternType === questionPatterns.DATE_TIME &&
                              <div
                                className={question?.doNotDisplayQuestion ?
                                  "width-98 child-margin-0" : "width-45 child-margin-0"}
                                key={question?.questionBankID}>
                                <div className='margin-top-5'>
                                  <DateField
                                    name={question?.questionBankID}
                                    label={question?.questionName}
                                    value={question?.userProvidedAnswer}
                                    readOnly={!isSaveEnabled}
                                    onChange={(name, value) => { handleTextChange(name, value) }}
                                    required={question?.setValidations}
                                    disableFuture={question?.gridSchema === dateValidationTypeValues.ALLOW_FUTURE_DATES ? false : true}
                                    onBlur={questionsDetails.handleBlur}
                                    error={questionsDetails?.touched[question?.questionBankID] && Boolean(questionsDetails.errors[question.questionBankID])}
                                    errorMessage={questionsDetails?.touched[question?.questionBankID] && questionsDetails.errors[question.questionBankID]} />
                                </div>
                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                  {question?.description}
                                </a>
                              </div>
                            }
                            {
                              (question?.questionPatternType === questionPatterns.RAIDO ||
                                question?.questionPatternType === questionPatterns.RAIDO_HORIZONTAL) &&
                              <div className='width-98' key={question?.questionBankID}>

                                <div className={isSaveEnabled ? 'margin-top-5' : 'disabled-div'}>
                                  <RadioGroupField
                                    name={question?.questionBankID}
                                    label={question?.doNotDisplayQuestion ? "" : question?.questionName}
                                    value={question?.questionAnswerID}
                                    onChange={(name, value) => { handleSelectChange(name, value) }}
                                    options={question?.questionAnswer
                                      ?.filter(soption => soption.childID === 0)
                                      .map(option => ({ label: option.answer, value: option.questionAnswerID }))}
                                    isBorder={question?.questionPatternType === questionPatterns.RAIDO}
                                    readOnly={!isSaveEnabled}
                                    required={question?.setValidations}
                                    onBlur={questionsDetails.handleBlur}
                                    error={questionsDetails?.touched[question?.questionBankID] && Boolean(questionsDetails.errors[question.questionBankID])}
                                    errorMessage={questionsDetails?.touched[question?.questionBankID] && questionsDetails.errors[question.questionBankID]} />
                                </div>
                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                  {question?.description}
                                </a>
                              </div>
                            }
                            {
                              question?.questionPatternType === questionPatterns.FILE_UPLOAD &&
                              <div className={isSaveEnabled ? 'width-98' : 'disabled-div width-98'}>
                                <div className="legalFieldWrapper ">
                                  <div className='space-between'>
                                    <div className='child-margin-5'>
                                      <div>{question?.questionName} <span className='error-message'>{question?.setValidations ? "*" : ""}</span></div>
                                      <a href={question?.description} target="_blank" rel="noopener noreferrer">
                                        {question?.description}
                                      </a>

                                    </div>
                                    <div className='child-margin-5'>
                                      <div>
                                        {
                                          (question?.userProvidedAnswer === "" || question?.userProvidedAnswer instanceof Blob || question?.userProvidedAnswer instanceof File) ?
                                            <div>
                                              <FileUploadBtn
                                                name={question?.questionBankID}
                                                defaultFile={question?.userProvidedAnswer}
                                                label="UPLOAD DOCUMENT"
                                                maxSize={5}
                                                onChange={(name, value) => handleFileUpload(name, value)}
                                                disabled={!isSaveEnabled} />
                                              {
                                                (questionsDetails?.touched[question?.questionBankID] && Boolean(questionsDetails.errors[question.questionBankID])) &&
                                                <div className='error-message'>
                                                  {questionsDetails.errors[question.questionBankID]}
                                                </div>
                                              }
                                            </div>
                                            :
                                            <div className='child-row-margin-5 align-items-center'>
                                              {/* <div>{question?.userProvidedAnswer?.split()}</div>
                                                      <div>{question?.userProvidedAnswer?.split("_")?.[0]}</div> */}
                                              <div className='kycattachedfile'>{question?.userProvidedAnswer?.split("/")?.[question?.userProvidedAnswer?.split("/")?.length - 1]}</div>
                                              <div>
                                                <Tooltip title="Download File">
                                                  <Button
                                                    className='margin-right-minus-20'
                                                    onClick={() => { onDownloadClick(question?.userProvidedAnswer?.split("_")?.[0], question?.userProvidedAnswer?.split("/")?.[question?.userProvidedAnswer?.split("/")?.length - 1]) }}>
                                                    <DownloadIcon />
                                                  </Button>
                                                </Tooltip>
                                                {
                                                  secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD &&
                                                  <Button disabled={!isSaveEnabled}><CloseIcon onClick={() => { handleFileUpload(question?.questionBankID, "") }} /* onClick={()=>{onDeleteClick(question?.userProvidedAnswer?.split("_")?.[0])}} */ /></Button>
                                                }
                                              </div>
                                            </div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  {/* <Divider /> */}
                                </div>
                              </div>
                            }
                          </> : ""
                      }
                    </>
                  )
                })
            }
          </div> :
          <Grid><LinearProgress /></Grid>
        }
      </div>
      <ToastContainer />
    </div>
  )
}

export default QuestionsModel